/* eslint-disable @typescript-eslint/no-explicit-any */
import { Formatter } from "@with-nx/hooks-n-helpers";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import Colors from "../../../../helpers/src/lib/Colors";
import DesignedIcon from "../designed-icon/designed-icon";
import DesignedTag from "../designed-tag/designed-tag";
import Rule from "../rule/rule";
import { DesignedCardCart, DesignedCardPrice } from "./designed-card";

const DesignedBrandLogo = dynamic(
  () => import("../designed-brand-logo/designed-brand-logo")
);

type TagPosition = "top-left" | "top-right" | "bottom-left" | "bottom-right";

interface InsideCardProps {
  tags?: string[];
  tagPosition?: TagPosition;
  title?: string;
  subtitle?: string;
  branding?:
    | "stage-guide"
    | "stage-player"
    | "stage-gear"
    | "musical-theatre-international"
    | "theatrical-rights-worldwide"
    | "broadway-licensing";
  large?: string;
  extraLarge?: string;
  cart?: DesignedCardCart;
  price?: DesignedCardPrice;
  image?: string;
  imageFit?: string;
  color?: string;
  press?: () => unknown;
  click?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => unknown;
  controls?: JSX.Element[];
  alt?: string;
  labelTextShadow?: boolean;
  controlsPosition?: TagPosition;
}

export const InsideCard: React.FC<InsideCardProps> = ({
  tags,
  tagPosition = "bottom-right",
  title,
  subtitle,
  branding,
  large,
  extraLarge,
  cart,
  price,
  image,
  imageFit = "contain",
  press,
  click,
  controls,
  controlsPosition = "top-left",
  color,
  alt,
  labelTextShadow,
}) => {
  const ShoppingCartOverlay = ({ cart }: { cart: DesignedCardCart }) => {
    const router = useRouter();

    const authenticate = () => {
      if (!window) {
        return;
      }

      const _url = new URL(window?.location?.origin + "/auth");
      _url.search = new URLSearchParams({
        redirect: window.location.pathname,
        action: "Continue",
        form: "register",
        ...cart.properties,
      }).toString();
      router.push(_url.toString());
    };

    return (
      <Box
        parse="p:absolute b:10 r:10 w:32 h:32 br:5 d:inline-flex a:center j:center"
        border="1px solid rgba(255, 255, 255, 0.1)"
        color={cart.added ? Colors.positive : Colors.accent}
        css="--cart"
        press={(event: MouseEvent) => {
          event?.preventDefault();

          if (cart?.authenticated === false) {
            authenticate();
          } else {
            if (cart?.press) {
              cart.press();
            }
          }
        }}
        native={{
          cypress: `button-add-to-cart${
            cart?.cypressTitle ? `-${cart.cypressTitle}` : ""
          }`,
        }}
      >
        <DesignedIcon
          name="cart/bold"
          size={24}
          color={cart.added ? Colors.font1 : Colors.font3}
        />
      </Box>
    );
  };

  const positionStyles = {
    "top-left": "t:10 l:10",
    "top-right": "t:10 r:10",
    "bottom-left": "b:10 l:10",
    "bottom-right": "b:10 r:10",
  };

  const [colors] = useState<{
    r: number;
    g: number;
    b: number;
    error?: unknown;
    message?: string;
    ready?: boolean;
  }>({
    r: 0,
    g: 0,
    b: 0,
    ready: true,
  });

  const _color = Formatter.rgb(color || "");

  const calculateBestTextColor = (color: {
    r: number;
    g: number;
    b: number;
  }) => {
    const average = (color.r + color.g + color.b) / 3;
    const isLight = average > 72;

    return isLight ? Colors.background : Colors.font1;
  };

  return (
    <>
      <Box
        parse="w:100% h:100% br:5 oa:visible t:0 l:0 p:absolute d:block z:3 i:3"
        css="inside-card"
        press={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          click?.(event)
        }
        style={
          color === "none"
            ? undefined
            : color && _color
            ? {
                backgroundColor: "var(--foreground)",
                backgroundImage: `linear-gradient(to top, rgba(${_color.r}, ${_color.g}, ${_color.b}, 0.9), rgba(${_color.r}, ${_color.g}, ${_color.b}, 0.5))`,
              }
            : colors.ready && image
            ? {
                backgroundColor: "var(--foreground)",
                backgroundImage: `linear-gradient(to top, rgba(${colors.r}, ${colors.g}, ${colors.b}, 0.8), rgba(${colors.r}, ${colors.g}, ${colors.b}, 0.1))`,
              }
            : {}
        }
      >
        <Box parse="p:relative w:100% h:100% br:5 oa:visible">
          <Box parse="p:relative w:100% h:100% br:5 oa:visible">
            {price && (
              <Box parse="d:inline-flex a:flex-end fd:column p:absolute t:10 r:10 l:unset b:unset">
                {price.undiscounted && (
                  <Rule
                    color={Colors.font4}
                    rule="lt"
                    bottom={5}
                    display="block"
                    style={{ textDecoration: "line-through" }}
                  >
                    {price.undiscounted}
                  </Rule>
                )}
                {title && (
                  <Rule color={Colors.font1} rule="lm" display="block">
                    {price.price}
                  </Rule>
                )}
              </Box>
            )}
            {controls && (
              <Box
                parse={`d:inline-flex a:flex-end fd:column p:absolute ${
                  controlsPosition
                    ? positionStyles[controlsPosition]
                    : "t:10 r:10"
                } l:unset b:unset`}
              >
                <>{controls}</>
              </Box>
            )}
            {image ? (
              <Box
                parse="p:absolute t:0 l:0 b:0 r:0 d:flex a:center j:center pa:0 oa:hidden"
                press={press}
              >
                <img
                  alt={alt || title || "Broadway Media"}
                  loading="lazy"
                  src={image}
                  style={{
                    width: "90%",
                    height: "90%",
                    borderRadius: 5,
                    overflow: "hidden",
                    objectFit: imageFit as any,
                  }}
                />
              </Box>
            ) : undefined}

            {extraLarge ? (
              <Box parse="p:absolute t:0 l:0 w:100% h:100% d:flex a:center j:center pa:20">
                <Rule
                  rule="llx"
                  color={Colors.font1}
                  style={{
                    textShadow: labelTextShadow ? "0 0 4px #000" : "none",
                  }}
                >
                  {extraLarge}
                </Rule>
              </Box>
            ) : undefined}
            {large && !image ? (
              <Box parse="p:absolute t:0 l:0 w:100% h:100% d:flex a:center j:center pa:20">
                <Rule
                  rule="ht"
                  weight="700"
                  align="center"
                  tag="span"
                  opacity={0.9}
                  color={
                    color && !image
                      ? calculateBestTextColor(
                          _color || {
                            r: 0,
                            g: 0,
                            b: 0,
                          }
                        )
                      : Colors.font1
                  }
                >
                  {large}
                </Rule>
              </Box>
            ) : undefined}

            {cart ? <ShoppingCartOverlay cart={cart} /> : undefined}
            {tags ? (
              <Box
                parse={`p:absolute pa:5 ${
                  positionStyles[tagPosition]
                } d:inline-flex fd:column a:${
                  tagPosition.includes("left") ? "flex-start" : "flex-end"
                }`}
              >
                {tags.map((tag) => (
                  <Box parse="mt:5" key={tag}>
                    <DesignedTag
                      adaptive={true}
                      size="small"
                      color={Colors.font2}
                      background={Colors.entity}
                      label={tag}
                    />
                  </Box>
                ))}
              </Box>
            ) : undefined}
            {title || branding || subtitle ? (
              <Box
                parse={`w:100% ${positionStyles[tagPosition]} pa:10 l:0 b:0 t:unset r:unset d:flex a:flex-end p:absolute`}
                justify={
                  title || subtitle
                    ? "space-between"
                    : cart && tags
                    ? "space-between"
                    : "flex-end"
                }
              >
                {(title || subtitle) && (
                  <Box parse="f:1 mr:24">
                    {title && (
                      <Rule
                        color={Colors.font1}
                        rule="lm"
                        display="block"
                        style={{
                          textShadow: labelTextShadow ? "0 0 8px #000" : "none",
                        }}
                      >
                        {title}
                      </Rule>
                    )}
                    {subtitle && (
                      <Rule
                        color={Colors.font4}
                        rule="lt"
                        top={5}
                        display="block"
                      >
                        {subtitle}
                      </Rule>
                    )}
                  </Box>
                )}

                {branding && <DesignedBrandLogo mode={branding} height={24} />}
              </Box>
            ) : undefined}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InsideCard;
